import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoadingController, ToastController } from '@ionic/angular';
import { ApiService } from '../api/api.service';
import { Router } from '@angular/router';

export interface DadoMunicipioPrestador {
  id: number;
  municipio_id: string;
  prestador_id: string;
  ano_id: string;
  valor: string;
  favorito: boolean;
}

export interface DadoUsuario {
  sigla: string;
  tipo: string;
  id: number;
  editar: boolean;
  ano: string;
  origem_valor: string;
  municipio_id: string;
  prestador_id: string;
  ano_id: string;
  unidade_medida: string;
  valor: JSON;
  dado_id: string;
  favorito: boolean;
  descricao: string;
}

const TABELA = "dado_municipio_prestador"

@Injectable({
  providedIn: 'root'
})
export class DadoMunicipioPrestadorService {

  loading: any;

  constructor(
    private storage: Storage,
    private apiService: ApiService,
    private toastController: ToastController,
    public loadingController: LoadingController,
    private router: Router,
  ) { }

  getDadoMunicipioPrestadorById(id) {
    return this.storage.get(TABELA).then(
      (dadosMunicipioPrestador) => {
        return dadosMunicipioPrestador.find(
          (dadoMunicipioPrestador) => dadoMunicipioPrestador.id === parseInt(id)
        )
      }
    );
  }

  getAllDadoMunicipioPrestador(): Promise<DadoMunicipioPrestador[]> {
    return this.storage.get(TABELA);
  }

  async getAllMunicipioPrestador(municipio_id: number, prestador_id: number, usuario_id: number, ano_id: number): Promise<DadoUsuario[]> {
    var newDados: DadoUsuario[] = [];
    const get_registros = async () => {
      this.apiService.sendData(
        {
          municipio_id: municipio_id,
          prestador_id: prestador_id,
          usuario_id: usuario_id,
          ano_id: ano_id,
        },
        "get_dado_municipio_prestador_usuario"
      ).subscribe(
        (retorno) => {
          if (retorno.length > 0) {
            retorno.forEach(element => {
              newDados.push(element);
            });
          }
        }
      )
    }
    await get_registros();
    return newDados;
  }

  async getAllMunicipioPrestadorFavorito(municipio_id: number, prestador_id: number, usuario_id: number, ano_id: number): Promise<DadoUsuario[]> {
    var newDados: DadoUsuario[] = [];
    const get_registros = async () => {
      this.apiService.sendData(
        {
          municipio_id: municipio_id,
          prestador_id: prestador_id,
          usuario_id: usuario_id,
          ano_id: ano_id,
        },
        "get_dado_municipio_prestador_usuario_favorito"
      ).subscribe(
        (retorno) => {
          if (retorno.length > 0) {
            retorno.forEach(element => {
              newDados.push(element);
            });
          }
        }
      )
    }
    await get_registros();
    return newDados;
  }

  setFavorito(dadoMunicipioPrestador: DadoUsuario): any {
    this.apiService.sendData(
      {
        id: dadoMunicipioPrestador.id,
        favorito: dadoMunicipioPrestador.favorito
      },
      "set_dado_municipio_prestador"
    ).subscribe(
      (retorno) => {
        this.showMensage("Registro alterado com sucesso.", 'success');
      },
      (erro) => {
        let msg = '';
        if (erro.error != undefined) {
          msg = erro.error.message
        } else {
          msg = erro.message
        }
        this.showMensage(msg, 'danger')
        return false;
      }
    );
  }

  async envioDadosDadoMunicipioPrestador(dadoMunicipioPrestador: DadoMunicipioPrestador) {
    const envia_registro = async () => {
      this.apiService.sendData(
        dadoMunicipioPrestador,
        "set_dado_municipio_prestador"
      ).subscribe(
        (retorno) => {
          this.showMensage("Registro alterado com sucesso.", 'success');
          this.setRetornoApi(dadoMunicipioPrestador, retorno);
          this.router.navigate(['dados'], { skipLocationChange: true });
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage(msg, 'danger')
          return false;
        }
      );
    }
    await envia_registro();
  }

  async sincronizaDadoMunicipioPrestador() {
    const atualiza_registros = async () => {
      return this.apiService.syncData('sincroniza_dado_municipio_prestador').subscribe(
        (retorno) => {
          if (retorno.length > 0) {
            this.addDadoMunicipioPrestadorSync(retorno);
          }
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage("Erro ao sincronizar dado. :" + msg, 'danger');
        }
      );
    }
    await atualiza_registros();
  }

  async addDadoMunicipioPrestadorSync(dadosMunicipioPrestador: DadoMunicipioPrestador[]): Promise<any> {
    return this.storage.set(TABELA, dadosMunicipioPrestador);
  }


  private async addDadoMunicipioPrestador(dadoMunicipioPrestador: DadoMunicipioPrestador): Promise<any> {
    return this.storage.get(TABELA).then((dadosMunicipioPrestador: DadoMunicipioPrestador[]) => {
      if (dadosMunicipioPrestador) {
        dadosMunicipioPrestador.push(dadoMunicipioPrestador);
        this.storage.set(TABELA, dadosMunicipioPrestador);
      } else {
        this.storage.set(TABELA, [dadoMunicipioPrestador]);
      }
    });
  }

  private updateDadoMunicipioPrestador(dadoMunicipioPrestador: DadoMunicipioPrestador): Promise<any> {
    return this.storage.get(TABELA).then((dadosMunicipioPrestador: DadoMunicipioPrestador[]) => {
      if (!dadosMunicipioPrestador || dadosMunicipioPrestador.length === 0) {
        return null;
      }

      let newDadosMunicipioPrestador: DadoMunicipioPrestador[] = [];

      for (let i of dadosMunicipioPrestador) {
        if (i.id === dadoMunicipioPrestador.id) {
          newDadosMunicipioPrestador.push(dadoMunicipioPrestador);
        } else {
          newDadosMunicipioPrestador.push(i);
        }
      }

      return this.storage.set(TABELA, newDadosMunicipioPrestador);
    });
  }

  private setRetornoApi(dadoMunicipioPrestador: DadoMunicipioPrestador, retorno) {
    if (dadoMunicipioPrestador.id != null && dadoMunicipioPrestador.id != undefined) {
      this.updateDadoMunicipioPrestador(dadoMunicipioPrestador);
    }
    else {
      dadoMunicipioPrestador.id = retorno.id;
      this.addDadoMunicipioPrestador(dadoMunicipioPrestador);
    }

  }

  private async showMensage(msg, color) {
    const toast = await this.toastController.create({
      message: msg,
      color: color,
      duration: 10000
    });
    toast.present();
  }

  private async showLoading(msg: string) {
    await this.loadingController.create({
      message: msg,
      spinner: 'crescent',
      id: 'sync_dados'
    }).then(
      (v) => {
        this.loading = v;
        v.present();
      }
    );
  }
}
